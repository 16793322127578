import { User } from '../../utils/mediaApi';
import { ChannelStatus } from '../../utils/mediaApi/me';
import { store } from '../../store/store';
import { FEATURE_FLAGS, getFeatureFlagValue } from '../../utils/featureFlags/featureFlags';

export const getChannelName = (channel?: User): string => {
	return channel?.fullName ?? channel?.name ?? '';
};

export const navigateToEditChannel = (channelUid: string) => {
	const baseUrl = getFeatureFlagValue(FEATURE_FLAGS.ENABLE_BUSINESS_APP_BASE)
		? store.getState().endpoints.BUSINESS_APP_BASE
		: store.getState().endpoints.PUBLISH_APP_BASE;
	window.open(`${baseUrl}/edit-channel/${channelUid}`, '_blank')?.focus();
};

export const navigateToRecastPublish = () => {
	const baseUrl = getFeatureFlagValue(FEATURE_FLAGS.ENABLE_BUSINESS_APP_BASE)
		? store.getState().endpoints.BUSINESS_APP_BASE
		: store.getState().endpoints.PUBLISH_APP_BASE;
	window.open(`${baseUrl}`, '_blank')?.focus();
};

export const getIsMyChannel = (channelUid: string): boolean => {
	const myChannels = store.getState().me.channels;
	return !!myChannels?.some(channel => channel.uid == channelUid);
};

export const getIsLiveChannel = (channel?: User): boolean => {
	return channel?.status === ChannelStatus.LIVE;
};

export const getLiveChannels = (channels?: User[]) => {
	return channels?.filter(channel => channel.status === ChannelStatus.LIVE && !channel.isAgent);
};

export const getNotLiveChannels = (channels?: User[]) => {
	return channels?.filter(channel => channel.status !== ChannelStatus.LIVE);
};

export const getDarkChannels = (channels?: User[]) => {
	return channels?.filter(channel => channel.status === ChannelStatus.READY);
};

export const getNotDeletedChannels = (channels?: User[]) => {
	return channels?.filter(channel => channel.status !== ChannelStatus.DELETED);
};

export const findChannelWithId = (channelId?: string): User | undefined => {
	const discoverUsers = store.getState().content.dashboard.discover.publishers.users;
	return discoverUsers.find(channel => channel.uid === channelId);
};
