import flagsmith from 'flagsmith';
import { decodeJwtToken, TokenPayload } from '../authApi/jwt';
import { addAuthStateListener } from '@content-technology-partners-ltd/shared-data-access';

export const FEATURE_FLAG_VALUES = {
	REMOVE_VIDEO_CARD_WATCH_BUTTON: 'remove_video_card_watch_button'
};

export const FeatureFlagValue = new Map([
	[FEATURE_FLAG_VALUES.REMOVE_VIDEO_CARD_WATCH_BUTTON, { json: false, fallback: false }]
]);

export type FlagValueKeys = typeof FeatureFlagValue extends ReadonlyMap<infer K, any> ? K : never;

export const FEATURE_FLAGS = {
	LIVE_CHAT_AUTOLOAD: 'live_chat_autoload',
	MY_DATA_WALLET_2: 'my_data_wallet_2',
	ENABLE_COMMENTS: 'enable_comments',
	ENABLE_SOCIAL_SIGNUP_AUTO_FOLLOW: 'enable_social_signup_auto_follow',
	SIGNUP_PROVIDERS: 'signup_providers',
	ENABLE_PURCHASE_REDEMPTION_SPLIT: 'enable_purchase_redemption_split',
	ENABLE_WARN_ABOUT_GEOBLOCK: 'enable_warn_about_geoblock',
	USE_NEW_ONETRUST_SCRIPT: 'use_new_onetrust_script',
	ENABLE_BUSINESS_APP_BASE: 'enable_business_app_base',
	ENABLE_ACCOUNT_WALLET_LOZENGE: 'enable_account_wallet_lozenge',
	ACCOUNT_POPUP_ENABLED: 'account_popup_enabled'
} as const;

export type FeatureFlagName = typeof FEATURE_FLAGS[keyof typeof FEATURE_FLAGS] | FlagValueKeys;

export const getFeatureFlagValue = (featureFlagName: FeatureFlagName): boolean => {
	return flagsmith.hasFeature(featureFlagName);
};

export const getFeatureFlagValues = (featureFlagName: FeatureFlagName) => {
	return flagsmith.getValue(featureFlagName);
};

async function identifyUserForFlagsWithPayload(decodedToken: TokenPayload) {
	const uid = decodedToken.uid;

	await flagsmith.identify(uid, {
		isAdmin: !!decodedToken.isAdmin,
		isContributor: !!decodedToken.isContributor,
		isPartnerStaff: !!decodedToken.isPartnerStaff,
		isPublisher: !!decodedToken.isPublisher
	});
}

export const identifyUserForFlags = async (token: string) => {
	const decodedToken = decodeJwtToken(token);

	if (!decodedToken) return;

	await identifyUserForFlagsWithPayload(decodedToken);
};

export const stopIdentifyingUserForFlags = async () => {
	return await flagsmith.logout();
};

export const setupFeatureFlagsListeners = () => {
	let previousPayload: TokenPayload | null = null;

	return addAuthStateListener(async state => {
		const payload = state.tokenPayload;

		if (previousPayload === payload) {
			return;
		}
		previousPayload = payload;

		if (payload) {
			await identifyUserForFlagsWithPayload(payload);
		} else {
			await stopIdentifyingUserForFlags();
		}
	});
};
